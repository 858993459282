import { format, isSameDay, isWithinInterval, set } from "date-fns";
import React from "react";
import { AutoRefreshPage } from "./AutoRefresh";
import { Clock } from "./Clock";
import { lFormat } from "./functions";

interface IActivityItem {
	startDate: Date;
	description: string;
}

const ActivityItem = (props: IActivityItem) => {
	if (!isSameDay(new Date(), props.startDate)) return null;
	return (
		<section className="flex text-5xl border-2 rounded-xl shadow w-full m-4">
			<div className="flex items-center justify-center border-r w-44 px-2 shrink-0">
				{lFormat(props.startDate, "HH:mm")}
			</div>
			<div className="p-2 flex items-center">{props.description}</div>
		</section>
	);
};

interface ISpecialItem {
	startDate: Date;
	endDate: Date;
	description: string;
}

const SpecialItem = (props: ISpecialItem) => {
	if (
		!isWithinInterval(new Date(), {
			start: props.startDate,
			end: props.endDate,
		})
	)
		return null;
	return (
		<section className="flex text-4xl w-full m-4 items-center justify-center">
			<div className="p-2 flex items-center text-red-500 uppercase underline font-bold">
				⚠️ {props.description}
			</div>
		</section>
	);
};

const App = () => {
	const now = new Date();
	const formatted = format(now, "aaa"); // 'aaa' for AM/PM
	console.log("formatted", formatted);

	const isAM = formatted === "am";
	const isPM = formatted === "pm";

	return (
		<main className="h-full w-full flex overflow-auto bg-black relative">
			<button
				className="absolute right-2 top-2 px-2 border rounded bg-white text-black"
				onClick={() => window.location.reload()}
			>
				R
			</button>
			<AutoRefreshPage />
			<div className="hidden font-mono w-1/2 text-7xl flex flex-col justify-center items-center py-8 border-r shrink-0">
				<section className="uppercase font-bold text-8xl">{`${lFormat(
					new Date(),
					"EEEE"
				)}`}</section>
				<section className="uppercase text-6xl">{`${
					isAM ? "MATIN" : "APRES-MIDI"
				}`}</section>
				<section className="uppercase text-9xl mt-auto mb-12">{`${lFormat(
					new Date(),
					"HH:mm"
				)}`}</section>
				<footer className="uppercase text-5xl mt-auto">{`${lFormat(
					new Date()
				)}`}</footer>
			</div>
			<div className="shrink-0 flex flex-col font-semibold items-center w-full p-4 text-white">
				<header className="uppercase text-7xl text-center py-2 mt-4">
					<span className="px-4">Programme du jour</span>
				</header>
				<section className="uppercase text-6xl text-center py-4 mt-2 mb-8">
					<span>{`${lFormat(new Date(), "PP")}`}</span>
					<Clock />
				</section>
				<SpecialItem
					startDate={set(new Date(), {
						year: 2024,
						month: 5,
						date: 5,
						hours: 9,
						minutes: 30,
					})}
					endDate={set(new Date(), {
						year: 2024,
						month: 5,
						date: 6,
						hours: 10,
						minutes: 0,
					})}
					description={
						"Mettre ton réveil pour demain matin 8h00! On va chez le juge de paix."
					}
				/>
				<ActivityItem
					startDate={set(new Date(), {
						year: 2024,
						month: 3,
						date: 25,
						hours: 10,
						minutes: 0,
					})}
					description={"Ouvrir les stores et aérer un peu!"}
				/>
				<ActivityItem
					startDate={set(new Date(), {
						year: 2024,
						month: 3,
						date: 27,
						hours: 14,
						minutes: 0,
					})}
					description={"Lecture 📖 🌈 🪻 🌺 🙂 🇫🇮"}
				/>
				<ActivityItem
					startDate={set(new Date(), {
						year: 2024,
						month: 3,
						date: 25,
						hours: 11,
						minutes: 30,
					})}
					description={
						"Emile-Eino vient manger à 11h40. Repas prévu: boulettes de viande, riz et un légume si tu as."
					}
				/>
				<ActivityItem
					startDate={set(new Date(), {
						year: 2024,
						month: 4,
						date: 24,
						hours: 14,
						minutes: 0,
					})}
					description={
						"Appeler Raija & Vuokko pour aller en Finlande prochainement."
					}
				/>
				<ActivityItem
					startDate={set(new Date(), {
						year: 2024,
						month: 5,
						date: 4,
						hours: 11,
						minutes: 40,
					})}
					description={
						"👦 Emile-Eino et Lucile-Aina viennent manger 🍽️ vers 11h40!"
					}
				/>
				<ActivityItem
					startDate={set(new Date(), {
						year: 2024,
						month: 4,
						date: 23,
						hours: 11,
						minutes: 30,
					})}
					description={
						"👦 Emile-Eino vient manger vers 11h40! Idée de repas: sticks de poisson et riz."
					}
				/>
				<SpecialItem
					startDate={set(new Date(), {
						year: 2024,
						month: 4,
						date: 20,
						hours: 17,
						minutes: 30,
					})}
					endDate={set(new Date(), {
						year: 2024,
						month: 4,
						date: 20,
						hours: 23,
						minutes: 59,
					})}
					description={
						"Mettre ton réveil pour demain matin 9h00! Les enfants viennent manger vers 11h40."
					}
				/>
			</div>
			<footer className="absolute w-full uppercase font-bold text-white bottom-2 left-0 text-2xl text-center">
				Toujours laisser allumé!
				<br /> Ne pas enlever ou déplacer cette tablette!
			</footer>
		</main>
	);
};

export default App;
