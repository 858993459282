import { useEffect } from "react";

export const AutoRefreshPage = () => {
	useEffect(() => {
		const interval = setInterval(() => {
			// Refresh the page
			window.location.reload();
		}, 60 * 60000); // 60000 milliseconds = 1 minute

		// Clear the interval on component unmount
		return () => clearInterval(interval);
	}, []);

	return null;
};
