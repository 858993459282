import * as Locales from "date-fns/locale";
import { format, Locale } from "date-fns";

/**
 *
 * @returns Short locale of browser
 */
export const getShortLocal = () => {
	const locale = navigator.languages ? navigator.languages[0] : navigator.language;
	const shortLocale = locale.split("-")[0].toLowerCase();
	return shortLocale.length === 2 ? shortLocale : "en";
};

export const dateLocales: {
	[k: string]: Locale;
} = {
	en: Locales.enUS,
	de: Locales.de,
	fr: Locales.fr,
	"fr-CH": Locales.frCH,
};

const locale = dateLocales[getShortLocal()];

// it will format dates in whichever way is appropriate to the locale
export const lFormat = (date: Date | number, formatStr = "PP") => {
	try {
		switch (typeof date) {
			case "number":
				return format(date, formatStr, { locale });
			case "string":
				return format(Number(date), formatStr, { locale });
			default:
				return format(date, formatStr, { locale });
		}
	} catch (error) {
		console.log("lFormat error", date);
		return "n-a";
	}
};
