import { useState } from "react";
import { lFormat } from "./functions";

export const Clock = () => {
	let time = new Date().toLocaleTimeString();

	const [ctime, setTime] = useState(time);
	const UpdateTime = () => {
		time = new Date().toLocaleTimeString();
		setTime(lFormat(new Date(), "HH:mm"));
	};
	setInterval(UpdateTime, 1000);
	return <span className="px-2 ml-4">{ctime}</span>;
};
